<template>
  <div class="container mb-4">
    <div class="my--coin-purchase">
      <v-tabs v-model="tab" center-active v-if="categories && categories.length > 0">
        <v-tab
          :href="`#tab-${index}`"
          v-for="(category, index) in categories"
          :key="`category-title-${index}`"
          @click="changeCategory(category)"
        >
          {{ category.name }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item
          :value="`tab-${index}`"
          v-for="(category, index) in categories"
          :key="`category-content-${index}`"
        >
          <SectionLoader :show="loading" />
          <v-simple-table class="mt-4">
            <thead>
              <tr>
                <th class="text-left">
                  {{ $t('coins.orderNumber') }}
                </th>
                <th class="text-left">
                  {{ $t('coins.productName') }}
                </th>
                <th class="text-left">
                  {{ $t('coins.purchaseDate') }}
                </th>
                <th class="text-left">
                  {{ $t('coins.expiredDate') }}
                </th>
                <th class="text-left">
                  {{ $t('coins.paid') }}
                </th>
                <th class="text-left">
                  {{ $t('coins.used') }}
                </th>
                <th class="text-left">
                  {{ $t('coins.expired') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(purchase, index) in purchases" :key="`coin-purchase-${index}`">
                <td>
                  <router-link :to="`/order?q=${purchase.order.order_number}`">{{
                    purchase.order.order_number
                  }}</router-link>
                </td>
                <td>{{ purchase.order.product_name }}</td>
                <td>{{ $date.format(purchase.acquired_at, 'dd MMM yyyy') }}</td>
                <td>{{ $date.format(purchase.expired_at, 'dd MMM yyyy') }}</td>
                <td>{{ purchase.coins_amount }}</td>
                <td>{{ purchase.coins_used }}</td>
                <td>{{ purchase.coins_expired }}</td>
              </tr>
            </tbody>
          </v-simple-table>
          <pagination
            :push-state="false"
            :meta="purchasesMeta.pagination"
            @changePage="changePage"
            :maxVisibleButtons="5"
            v-if="purchasesMeta"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import SectionLoader from '@/components/content-loading/section-loading';
import Pagination from '@/components/utils/fractal-pagination';
import { mapState } from 'vuex';

export default {
  name: 'index',
  components: {
    SectionLoader,
    Pagination,
  },
  data() {
    return {
      tab: 'tab-0',
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.coins.loading,
      categories: (state) => state.coins.categories,
      purchases: (state) => state.coins.purchases,
      purchasesMeta: (state) => state.coins.purchasesMeta,
    }),
  },
  methods: {
    async changeCategory(category) {
      this.$store.commit('coins/SET_CATEGORY', category);
      await this.$store.dispatch('coins/getAllPurchases', 1);
    },
    async changePage(page) {
      await this.$store.dispatch('coins/getAllPurchases', page);
    },
  },
  async mounted() {
    this.$store.commit('coins/SET_CATEGORY', this.categories[0]);
    await this.$store.dispatch('coins/getAllPurchases', 1);
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';
.container {
  padding-top: 0;
}
.my--coin-purchase {
  border: solid 1px $color-gray-1;
  border-radius: 16px;
  padding: 12px 24px;

  @media #{$phones} {
    border: none;
    padding: 12px 0;
  }

  .v-data-table thead tr {
    background-color: $color-beliruma-1;

    th {
      color: $color-white !important;
    }
  }
}
</style>
